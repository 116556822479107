import Link from 'next/link'
import React, { FC } from 'react'
import styles from './Tag.module.scss'
import cn from 'classnames'

export const Tag: FC<{ href?: string; name: string; className?: string }> = ({
  href,
  name,
  className,
}) => {
  if (!name && !href) {
    return null
  }
  return href ? (
    <Link href={href} className={cn(styles.link, className)} aria-label={name}>
      <span>[</span>
      <span className={styles.title}>{name}</span>
      <span>]</span>
    </Link>
  ) : (
    <span className={cn(styles.link, 'span-tag', className)}>
      <span>[</span>
      <span className={styles.title}>
        {name === 'freeDurov' ? '#' + name : name}
      </span>
      <span>]</span>
    </span>
  )
}
