import styles from './Grade.module.scss'
import React, { FC } from 'react'
import cn from 'classnames'

interface IGrade {
  grade: number
  className?: string
}

const GRADE = {
  '1': {
    label: 'Ужасно',
    type: 'awful',
  },
  '2': {
    label: 'Плохо',
    type: 'bad',
  },
  '3': {
    label: 'Нормально',
    type: 'normal',
  },
  '4': {
    label: 'Хорошо',
    type: 'good',
  },
  '5': {
    label: 'Отлично',
    type: 'great',
  },
  '0': {
    label: 'Нет оценки',
    type: 'zero',
  },
}

const Grade: FC<IGrade> = ({ grade, className }) => {
  const roundedGrade = Math.floor(grade)
  const label = GRADE[roundedGrade].label
  const type = styles[GRADE[roundedGrade].type]
  return (
    <div className={cn(styles.container, className)}>
      <p className="ls-03">Оценка:</p>
      <div title={label} className={cn('ls-03', styles.grade, type)}>
        {grade}
      </div>
    </div>
  )
}

export default Grade
