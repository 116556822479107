import React, { FC, useEffect, useState } from 'react'
import styles from './Notification.module.scss'
import cn from 'classnames'
import LogoIcon from '../../../../public/icons/aside-logo.svg'
import CloseIcon from '../../../../public/icons/close-pixel.svg'
import { createPortal } from 'react-dom'
import createContainer from '../createContainer'

type notificationType = 'error' | 'warning'

export interface INotification {
  type?: notificationType
  className?: string
  onDelete: () => void
  timeToDelete?: number
  duration?: number
}

const Notification: FC<INotification> = ({
  children,
  type = 'error',
  className,
  onDelete,
  timeToDelete = 300,
  duration = 3000,
}) => {
  const [container, setContainer] = useState(null)
  const [isClosing, setIsClosing] = useState(false)

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [isClosing, onDelete])

  useEffect(() => {
    setContainer(createContainer())
    setTimeout(() => setIsClosing(true), duration)
  }, [])

  if (container === null) return

  return createPortal(
    <div
      className={cn(
        styles.container,
        styles[type],
        isClosing ? styles.slideOut : styles.slideIn,
        className
      )}
    >
      <div className={styles.top}>
        <LogoIcon />
        <button
          type="button"
          onClick={() => setIsClosing(true)}
          className={styles.close}
        >
          <CloseIcon />
        </button>
      </div>
      {children}
    </div>,
    container
  )
}

export default Notification
