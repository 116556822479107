import React, { FC } from 'react'
import styles from './Reaction.module.scss'
import { IReaction } from '../../company/types'
import cn from 'classnames'

const Reaction: FC<IReaction> = ({ reaction, count = 0, onClick, loading }) => {
  const getIcon = () => {
    switch (reaction) {
      case 'negative':
        return (
          <span className={styles.reaction} role="img" aria-label="sheep">
            👎
          </span>
        )
      default:
        return (
          <span className={styles.reaction} role="img" aria-label="sheep">
            👍
          </span>
        )
    }
  }

  const getTitle = () => {
    switch (reaction) {
      case 'negative':
        return 'негативную'
      default:
        return 'позитивную'
    }
  }
  return (
    <button
      title={`Оставить ${getTitle()} реакцию`}
      onClick={onClick}
      disabled={loading}
      className={cn(styles.container, loading && styles.loading)}
    >
      {getIcon()} <span className={cn('ls-03', styles.count)}>{count}</span>
    </button>
  )
}

export default Reaction
