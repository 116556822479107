import React, { FC } from 'react'
import styles from './ReviewCard.module.scss'
import Image from '../common/Image'
import cn from 'classnames'
import Grade from '../common/Grade/Grade'
import Reactions from '../common/Reactions/Reactions'
import { getUtcDate, newsDate } from '../../utils/dates'
import { Tag } from '../common/Tag'
import Link from 'next/link'
import { IReviewCard } from '../../interfaces/ReviewCard'

const ReviewCard: FC<IReviewCard> = ({
  company,
  date,
  showReactions,
  rating,
  title,
  user,
  id,
  className,
}) => {
  return (
    <Link
      href={`/company/${company.slug}/${id}`}
      className={cn(styles.container, className)}
      target="_blank"
    >
      <div className={styles.top}>
        <Tag name="отзывы" />
        <time className={styles.date}>
          {newsDate({
            date: new Date(getUtcDate(date)),
            withYear: true,
            withComma: false,
          })}
        </time>
      </div>
      <div className={styles.content}>
        <div
          className={cn(
            styles.logoBox,
            !company?.logo && styles['logoBox--flex']
          )}
        >
          {company?.logo ? (
            <Image
              src={company.logo}
              width={100}
              height={100}
              alt={`${company.name}`}
              withOptimize
              skipSizes
            />
          ) : (
            <span>{company.name.slice(0, 1).toUpperCase()}</span>
          )}
        </div>
        <div>
          <div className={styles.user}>
            <div
              className={cn(
                styles.avatarBox,
                !user.avatar && styles['avatarBox--flex']
              )}
            >
              {user.avatar ? (
                <Image
                  src={user.avatar}
                  width={30}
                  height={30}
                  alt={`${user.name}`}
                  skipSizes
                />
              ) : (
                <span>{user.name.slice(0, 1).toUpperCase()}</span>
              )}
            </div>
            <p className={cn('ls-03', styles.title)}>
              {user.name} <br />
              написал отзыв о {company.name}
            </p>
          </div>
          <p className={cn('ls-03', styles.review)}>{title}</p>
          {(showReactions || rating) && (
            <div className={styles.bottom}>
              {rating && <Grade grade={rating} />}
              {showReactions && <Reactions id={id} />}
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default ReviewCard
