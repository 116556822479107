import React, { FC, useContext, useState } from 'react'
import styles from './Reactions.module.scss'
import Reaction from '../Reaction/Reaction'
import {
  useAddReviewLikeMutation,
  useDislikesByReviewIdQuery,
  useLikesByReviewIdQuery,
} from '../../../queries/generated/main/schema'
import { AuthContext } from '../../../contexts/AuthContext'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import cn from 'classnames'
import ErrorNotification from '../notify/ErrorNotification/ErrorNotification'
import useNotifications from '../../../hooks/useNotifications'

const Reactions: FC<{ id: string; className?: string }> = ({
  id,
  className,
}) => {
  const {
    refetch: likesRefetch,
    data: likesData,
    loading: likesLoading,
  } = useLikesByReviewIdQuery({
    variables: { id },
  })
  const likesCount = likesData?.reviews_likes_aggregated?.[0].count.value
  const {
    refetch: dislikesRefetch,
    data: dislikesData,
    loading: dislikesLoading,
  } = useDislikesByReviewIdQuery({ variables: { id } })
  const dislikesCount = dislikesData?.reviews_likes_aggregated?.[0].count.value
  const { addNotification, removeNotification, notifications } =
    useNotifications()
  const [send, { loading }] = useAddReviewLikeMutation()
  const { userData, needAuth, auth } = useContext(AuthContext)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const addReaction = async (value: 1 | -1) => {
    if (needAuth()) {
      auth()

      return
    }

    try {
      const token = await executeRecaptcha()

      await send({
        variables: {
          data: {
            review: parseInt(id),
            value,
            user: 0,
            recaptcha_token: token,
            tg_payload: {
              id: userData.id,
              auth_date: userData.auth_date,
              first_name: userData.first_name,
              hash: userData.hash,
              last_name: userData.last_name,
              photo_url: userData.photo_url,
              username: userData.username,
            },
          },
        },
      })

      likesRefetch()
      dislikesRefetch()
    } catch (err) {
      console.error(err)
      addNotification()
    }
  }

  return (
    <div className={cn(styles.reactions, className)}>
      <Reaction
        reaction="positive"
        count={likesCount}
        onClick={() => addReaction(1)}
        loading={likesLoading || loading}
      />
      <Reaction
        reaction="negative"
        count={dislikesCount}
        onClick={() => addReaction(-1)}
        loading={dislikesLoading || loading}
      />
      {!!notifications?.length &&
        notifications.map((notification) => (
          <ErrorNotification
            key={notification.id}
            onDelete={() => removeNotification(notification.id)}
          />
        ))}
    </div>
  )
}

export default Reactions
