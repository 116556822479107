import React, { FC } from 'react'
import styles from './ErrorNotification.module.scss'
import Notification, { INotification } from '../Notification'
import TgIcon from '../../../../public/icons/plane.svg'
import { MISTAKE_BOT_LINK } from '../../../../lib/constants/links'

interface IErrorNotification extends INotification {
  title?: string
  hideTgLink?: boolean
}

const ErrorNotification: FC<IErrorNotification> = ({
  onDelete,
  className,
  duration,
  timeToDelete,
  title,
  hideTgLink,
}) => {
  return (
    <Notification
      onDelete={onDelete}
      duration={duration}
      className={className}
      timeToDelete={timeToDelete}
    >
      <p className={styles.text}>{title || 'Тут что-то пошло не так…'}</p>
      {!hideTgLink && (
        <a target="_blank" href={MISTAKE_BOT_LINK} className={styles.link}>
          Сообщите нам об ошибке
          <div className={styles.iconBox}>
            <TgIcon />
          </div>
        </a>
      )}
    </Notification>
  )
}

export default ErrorNotification
