import { IReviewCard } from '../interfaces/ReviewCard'
import { ReviewsSliderQuery } from '../queries/generated/main/schema'

export const createReviewSlider = (data: ReviewsSliderQuery): IReviewCard[] => {
  if (!data) return []

  const slider: IReviewCard[] = data?.reviews?.map((review) => ({
    id: review.id,
    company: {
      ...review.company,
      logo: `${process.env.NEXT_PUBLIC_GRAPHQL_IMAGE}/${review.company?.logo}`,
    },
    date: review.date_created,
    review: review.text,
    title: review.name,
    user: {
      avatar: review.user?.photo_url,
      name: review.user?.username,
    },
    rating: review.grade,
  }))

  return slider
}
