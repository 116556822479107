import { useState } from 'react'

const useNotifications = () => {
  const [notifications, setNotifications] = useState<{ id: string }[]>([])

  function addNotification() {
    setNotifications((prev) => [...prev, { id: new Date().toString() }])
  }

  function removeNotification(id: string) {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    )
  }
  return { notifications, addNotification, removeNotification }
}

export default useNotifications
